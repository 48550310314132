@import "../theme/theme-fonts.scss";
@import "../theme/Palette/variable.scss";

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: $font-family-inter !important;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  line-height: $body-line-height;
  color: $body-color;
  background-color: $grey-100;
}

p {
  font-size: 13px;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $grey-700;
  text-align: "left";
  margin-top: 0;
  margin-bottom: 0;
}

h6 {
  font-size: 16px !important;
  font-weight: 600 !important;
}

h4 {
  font-family: $font-family-roboto !important;
}

.primary-color {
  color: $primary !important;
}

.secondary-color {
  color: $secondary !important;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.main-container-popup {
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  margin: 0px 0px 24px -8px;
}

.group-container {
  background-color: $white;
  color: $title-label-color;
  //border-radius: 16px;
  border-radius: 10px;
  padding: 24px !important;
  box-shadow: rgba(20, 46, 110, 0.1) 0px 1px 8px !important;
  //border: 1px solid $grey-200;
  //box-shadow: rgba(20, 46, 110, 0.1) 0px 1px 8px;
}

.profile-contianer {
  margin-right: -20px !important;
}

.template-table-container {
  // display: flex;
  // flex-wrap: wrap;
  border: 1px solid #0000001f;
  border-radius: "8px !important";
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .template-hide-small-screen {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .template-hide-medium-screen {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .OPD-date-button {
    margin-top: 0px !important;
  }
}

.add-icon {
  font-size: 12px !important;
}

.remove-note {
  font-size: 12px !important;
  color: $danger;
}

.note-container {
  padding: 10px;
}

.note-field-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: end;
  min-width: 100%;
}

.template-box p {
  padding: 16px 24px;
  font-weight: 600;
  //background-color: $text-bg-color;
}

//Added by MT
.template-box-sm p {
  padding: 16px 15px;
  font-weight: 600;
}

.list-container {
  background-color: $white;
  color: $title-label-color;
  // border-radius: 16px;
  // border: 1px solid $grey-200;
  box-shadow: rgba(20, 46, 110, 0.1) 0px 1px 8px !important;
  border-radius: 10px;
  padding: 24px !important;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-list-container {
  background-color: $white;
  color: $title-label-color;
  //border-radius: 16px 16px 0px 0px;
  border-radius: 10px 10px 0px 0px;
  //border: solid 1px $grey-200;
  box-shadow: rgba(20, 46, 110, 0.1) 0px 1px 8px !important;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dashboard-date-label {
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
  padding-top: 8px;

  .typography-first-child {
    font-weight: 700;
    font-size: 12px !important;
  }

  .typography-second-child {
    font-weight: 800;
    padding-top: 4px !important;
    font-size: 14px !important;
  }
}

.blue-link {
  color: $primary;
}

.fileds-label-text {
  font-size: $body-font-size !important;
  padding: 0px 0px 5px 5px !important;
}

.fields-label-date {
  padding-top: 100;
}

.app-bar {
  background-color: $sidebar-bg !important;
  color: $sidebar-menu-item-color !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.report-menu-ListItemCount {
  color: $primary;
  font-size: 14px;
}

.MuiPaper-root {
  box-shadow: none !important;
  padding-left: none;
}

.MuiAppBar-root {
  background-color: $background-color !important;
  box-shadow: none !important;
  padding-right: 0px !important; // logo side moving in mobile
}

.MuiTableCell-root {
  padding: 8px !important;
}

tbody .MuiTableRow-root:hover {
  background-color: $background-color !important;
}

.text-link {
  color: $primary !important;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.8;

  &:hover {
    text-decoration: underline;
  }
}

.text-link-danger {
  color: $danger !important;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.8;

  &:hover {
    text-decoration: underline;
  }
}

.doctor-name-link {
  font-size: 14px !important;
}

.MuiTableCell-sizeMedium {
  font-size: 0.813rem !important;
}

// -- for search header --

.plus-icon {
  color: $primary !important;
}

.page-header {
  margin-bottom: 16px;
}

.page-header-icon {
  display: flex;
  justify-content: end;
  color: black;
  gap: 16px;
  align-items: end;
}

.search-bar {
  display: flex;
  align-items: center;

  &.pdf-icon {
    padding-top: "10px";
    display: flex;
    justify-content: end;
  }

  .pdf-icon-hover {
    cursor: pointer;
  }
}

.title-button-responsive {
  display: flex;
  justify-content: space-between;
}

.pagination {
  padding: 8px 0px 0px 0px;
  display: flex !important;
  justify-content: space-between;

  .pagination-label {
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 12px;
  }

  .pagination-stack {
    display: flex;
    justify-content: end;
    align-items: center;
  }
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.input-group label {
  font-size: 13px;
  color: $grey-900;
}

.phone-input input {
  border: 1px solid $grey-400;
  border-radius: 3px;
  width: 85%;
  height: 100%;
  padding: 0px 10px 0px 10px;
}

.phone-input {
  width: 100%;
}

.phone-input input:focus {
  border: 2px solid $primary;
  outline: none;
}

.delete-container {
  font-size: 14px;
  height: 37px;
  color: #dd3431 !important;
}

.delete-container:hover {
  color: $white !important;
  background-color: #dd3431 !important;
  font-size: 14px;
}

.table {
  margin-top: 16px;
}

.item-grid-row:hover {
  font-size: 13px;
  //border: "1px solid #e0e0e0";
  border-radius: 4;
  padding: 10;
  //background-color: #fafbfd !important;
  background-color: #f1f5f8 !important;
}

.item-grid-row {
  font-size: 13px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
}

.vaccine-grid {
  font-size: 13px;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
}

.vaccine-grid:hover {
  font-size: 13;
  border: "1px solid #e0e0e0";
  border-radius: 4;
  padding: 10;
  //background-color: #fafbfd !important;
  background-color: #f5f1b9 !important;
}

.draggable-list-item {
  //background: $grey-400;
  background: #faf6ce;
}

.inner-header-container {
  padding: 20px;
  font-weight: 600;
  background-color: $hover-color;
}

.react-quill-alignment {
  .ql-editor {
    height: 150px;
  }
}

.inner-title-container {
  flex: 1;
  border-radius: 8px;
}

.title-container {
  flex: 1;
  padding: 10px 20px;
}

.search-container-draggable {
  padding: 10px;
  padding-right: 24px;
  padding-left: 24px;
}

.note-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: end;
  min-width: 100%;
}

.list-item-cursor {
  display: flex;
  cursor: pointer;
}

.symptom-text {
  //color: $danger;
  color: #000;
}

.symptom-text p {
  //color: $danger;
  color: #000;
  font-weight: 500 !important;
}

.button-responsive {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.button-responsive-calender {
  min-height: 40px;
  margin: 0px !important;
  border-radius: 8px !important;
}

.doctor-filter {
  margin-left: 10px !important;
}

// Button size to set small
.button-size {
  padding: 7px 15px 20px;
}

.multi-upload-button {
  display: flex;
  height: 36px;
  align-items: center;
  font-size: 12px !important;
  margin: 0px 25px;
  gap: 2px;

  .left-icon {
    text-align: center;
    margin-left: 2px;
    padding-top: 6px;
    margin-right: 2px;
  }
}

.multi-upload-info-text {
  font-size: 12px !important;
}

.multi-upload-right-icon {
  text-align: center;
  padding-top: 7px;
  padding-left: 3px;
  cursor: pointer !important;
}

.item-grid-row-container {
  font-size: 13px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
}

.action-icon-placement {
  display: flex;
  justify-content: end;
}

.patient-waiting-number {
  // margin-right: 5px;
  font-size: 13px;
}

.detail-header-icon-container {
  font-size: 13px !important;
  display: flex !important;
  justify-content: center !important;
}

.detail-header-icon {
  font-size: 16px !important;
}

.symptoms-container {
  color: $danger;
  text-transform: uppercase;
  font-weight: 600 !important;
}

.inner-tabs {
  border-radius: 4px;
  place-content: "center";
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 8px;
  padding-left: 8px;
  font-weight: 800;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-saperator {
  color: $grey-400;
  font-size: 12px !important;
  margin: 0px 4px;
}

.patient-container {
  font-size: 15px;
  text-transform: uppercase;
  padding-right: 3px;
  font-weight: 600;
}

//Profile
.user-detail-text {
  font-size: 14px;
  font-weight: 600;
}

// Chif Complaient
.add-field-container {
  border: 1px dashed gray;
  border-radius: 4px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-bottom: 5px;
  padding-top: 5px;
  font-size: 12px;
  margin-right: 40px;
  cursor: pointer;
}

.text-field-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.summary-container {
  display: flex;
  justify-content: center;
  margin-top: 14vh;
}

.summary-img {
  width: 40px;
  padding-bottom: 16px;
}

.summary-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  color: #333;
  font-weight: bolder;
  padding-bottom: 10px;
}

.summary-text1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  color: #333;
}

.summary-dialog-mainContainer {
  flex-direction: column;
  height: 80vh;
}

.summary-dialog-container {
  display: flex;
  justify-content: center;
  margin-top: 27vh;
}

.badge {
  color: $white !important;
  line-height: 8px !important;
  padding: 0px 8px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  text-transform: uppercase;
}

.badge-new {
  color: $danger-color !important;
  line-height: 8px !important;
  padding: 0px 8px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  text-transform: uppercase;
}

.badge-new1 {
  color: white !important;
  line-height: 8px !important;
  padding: 0px 8px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  text-transform: uppercase;
}

.badge-case-status {
  line-height: 10px !important;
  padding: 0px 8px !important;
  font-weight: 500 !important;
  width: max-content;
  // font-size: 12px !important;
}

.badge-transparent {
  background-color: transparent !important;
}

.badge-white {
  background-color: white !important;
}

.badge-success {
  background-color: $success-color !important;
}

.badge-warning {
  background-color: $warning-color !important;
}

.badge-dark {
  background-color: $dark-color !important;
}

.badge-danger {
  background-color: $danger-color !important;
}

.badge-status {
  background-color: #ebf3fd !important;
  border: solid 1px #a7cefd;
  color: #000 !important;
  text-transform: capitalize;
}

.mobile-label-color {
  color: $grey-700 !important;
}

.MuiTableCell-head {
  border-top: 1px solid rgba(224, 224, 224, 1);
  color: $sub-title !important;
  font-weight: bold !important;
  font-size: 13px !important;
  padding: 16px 8px !important;
  line-height: normal !important;
  font-family: $font-family-inter !important;
}

.MuiTableCell-body {
  color: $sub-title !important;
  font-size: 13px !important;
  padding: 8px 8px !important;
  line-height: normal !important;
  font-family: $font-family-inter !important;
}

.MuiButton-root {
  font-size: 13px !important;
  font-family: $font-family-inter !important;
}

.primary-button {
  font-family: $font-family-inter;
  text-transform: capitalize;
  // min-width: 100px !important;
}

.secondary-button {
  font-family: $font-family-inter;
  background-color: $white !important;
  text-transform: capitalize;
  min-width: 100px !important;
}

.branch-checkbox {
  padding-top: 20px;
  height: 100%;
}

.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.no-spinner {
  text-align: right;
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//Scroll bar css

// /* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0b0b4530;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $grey-600;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $grey-600;
}

.MuiMenu-root .MuiPaper-elevation {
  padding-right: none !important;
}

.css-a3wxjl {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 24px;
  margin-right: 24px;
}

.MuiList-root {
  min-width: 150px !important;
  padding-top: 0;
}

.MuiListItemText-secondary {
  font-weight: 600;
}

#popoverSettings .MuiPaper-elevation {
  background-color: $sidebar-bg !important;
  top: 64px !important;
}

.MuiTypography-root {
  font-family: $font-family-inter !important;
  font-size: 14px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-color-grey-600 {
  color: $grey-600 !important;
}

.time-picker {
  min-width: 14px !important;
  max-width: 140px !important;
}

@media only screen and (max-width: 768px) {
  .button-responsive {
    width: 100%;
    margin-top: 8px !important;
    padding-bottom: 10px !important;
  }
}

@media only screen and (max-width: 500px) {
  .doctor-filter {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 768px) {
  .OPD-Upload-Button {
    margin-top: 30px !important;
  }
}

.css-q4f0r5-MuiToolbar-root {
  padding-left: 0 !important;
  padding-right: 16px !important;
  padding-bottom: 10px !important;
}

@media only screen and (max-width: 600px) {
  .pagination {
    flex-direction: column;
  }

  .pagination-label {
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
  }

  .pagination-stack {
    justify-content: center !important;
    align-items: center;
  }

  .css-q4f0r5-MuiToolbar-root {
    padding-right: 0px !important;
  }
}

.profile {
  padding-left: 20px !important;
}

@media only screen and (max-width: 600px) {
  .set {
    display: flex;
    justify-content: center;
    /* Centers the photo horizontally */
    align-items: center;
  }
}

@media only screen and (max-width: 320px) {
  .from-controller {
    padding-top: 30px !important;
  }
}

@media only screen and (width: 768px) {
  .profile {
    padding-left: 80px !important;
  }
}

@media only screen and (max-width: 600px) {
  .set-height-small {
    height: "600px";
  }
}

@media only screen and (max-width: 768px) {
  .set-height-mobile {
    height: "600px";
  }
}

@media only screen and (min-width: 1024px) {
  .set-height-medium {
    height: "400px";
  }
}

@media only screen and (min-width: 1440px) {
  .set-height-large {
    height: "400px";
  }
}

@media only screen and (min-width: 1024px) {
  .profile {
    padding-left: 80px !important;
  }
}

.remove {
  border: none !important;
  box-shadow: none !important;
  padding-top: 10px !important;
  padding-bottom: 0px !important;
}

.ql-editor {
  background-color: $text-bg-color;
}

.ql-editor p {
  font-size: 14px;
}

.new1 {
  padding: 0px 0px 0px 0px !important;
}

.pa {
  padding-top: 13px;
  padding-bottom: 8px;
}

.new2 {
  padding-left: 16px !important;
}

.padding-0 {
  padding: 0px !important;
}

.dialog {
  min-width: 100px;
  max-width: 100%;
  margin: auto;
  border-radius: 4px !important;
}

@media (max-width: 768px) {
  .dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    overflow: auto;
  }
}

@media (min-width: 1024px) {
  .dialog {
    width: 600px;
    height: 625px;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
  }
}

.dialog1 {
  width: 100px;
  max-width: 100%;
  margin: auto;
  border-radius: 4px !important;
}

@media (max-width: 768px) {
  .dialog1 {
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    overflow: auto;
  }
}

@media (min-width: 1024px) {
  .dialog1 {
    width: 600px;
    height: 625px;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
  }
}

.padding-1 {
  padding-left: 10px;
}

.padding-remove-Investigation-Edit {
  padding: 0px !important;
}

.padding-add-Investigation {
  padding-left: 16px !important;
  padding-right: 8px !important;
  padding-top: 25px !important;
}

.main-dialog-background-color {
  background-color: #f1f5f8;
}

.validation-message {
  font-family: $font-family-inter !important;
  color: $danger !important;
}

.skeleton-input {
  height: 40px !important;
}

.skeleton-draggable-item {
  height: 45px !important;
}

.skeleton-opd-item {
  height: 100px !important;
}

.skeleton-box {
  height: 200px !important;
}

.skeleton-check-box {
  height: 20px !important;
  width: 20px !important;
  margin-left: 11px !important;
  margin-right: 16px !important;
}

.skeleton-table-header-check-box {
  height: 20px !important;
  width: 20px !important;
  margin-left: 11px !important;
  margin-right: 16px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.check-box {
  display: flex;
  align-items: center;
}

.skeleton-search-box {
  // width: 250px;
  max-width: 100%;
  height: 40px !important;
}

.skeleton-search-pdf-icon {
  height: 34px !important;
  width: 30px;
  margin: 0px 2px !important;
}

.skeleton-tab-total {
  height: 25px !important;
  width: 25px !important;
}

.skeleton-table-row-height-25 {
  height: 25px !important;
}

.skeleton-pagination-label {
  height: 35px !important;
  width: 200px;
}

.skeleton-pagination-stack {
  height: 35px !important;
  width: 200px;
}

.MuiOutlinedInput-root {
  background-color: #fafbfd;

  fieldset {
    border-color: "#e0e0e0" !important; //#{theme.palette.grey[300]}; -- ADD THIS COLOR IN VARIABLES
  }
}

.organization-component {
  max-height: 155px;
  overflow-y: auto;
  overflow-x: auto;
  border-bottom: 1px solid #e0e0e0;

  .organization-list-data {
    width: 100% !important;

    .MuiListItem-button {
      padding: 8px 8px;
    }

    .organization-list-icon {
      margin-right: 10px;
    }

    .organization-list {
      padding-left: 10px;
      width: 100%;
      font-weight: 600;
    }
  }

  .add-another-organization-containter {
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding: 8px 8px;
    border-radius: 4px;
  }

  .add-another-organization-containter-disable {
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding-top: 8px;
    padding-right: 11px;
    padding-bottom: 5px;
    border-radius: 0px;
    background-color: #e0e0e0;
  }

  .add-another-organization-containter:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
    cursor: pointer;
  }
}

//scrollbar
.organization-component::-webkit-scrollbar-track {
  //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: #f5f5f5;
}

.organization-component::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.organization-component::-webkit-scrollbar-thumb {
  border-radius: 8px;
  //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $grey-600;
}

.branch-selection-component {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;

  .branch-selection-btn {
    width: 223px;
    height: 37px;

    .branch-selection {
      color: #0b0b45 !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      /* for internet explorer */
      overflow: hidden;
      width: 190px;
      text-align: left;
      text-transform: none;
    }
  }
}

.nav-popover-branch {
  top: 1px !important;
}

.branch-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0px 0px;
  width: 242px;
}

.branch-container ul {
  width: 242px;
}

.branch-container ul div {
  padding: 2px 6px;
}

// .main-profie-container .MuiPaper-elevation {
//   border-radius: 8px;
//   top: 72px !important;
//   box-shadow: 0px 1px 2px 0px rgb(60 64 67 / 30%),
//     0px 2px 6px 2px rgb(60 64 67 / 15%) !important;

//   .profile-user-detail-component {
//     border-bottom: 1px solid $grey-200;
//     min-width: 295px !important;
//     max-width: 427px !important;
//     background-color: #fff;

//     .profile-component-grid {
//       .organization-container {
//         background-color: $warning-light;
//         width: 100%;
//         border: solid 1px $warning-light;
//         display: inline-flex;
//         align-items: center;
//         padding: 8px 8px;
//         border-radius: 4px;
//       }

//       .avatar-upload {
//         min-height: 74px;
//         margin-top: 16px;
//         margin-bottom: 16px;
//         width: 100% !important;

//         .avatar-edit {
//           position: absolute;
//           right: 19px;
//           z-index: 1;
//           bottom: 1px;
//         }

//         .avatar-preview {
//           width: 100%;
//           position: relative;
//           display: inline-flex;
//           justify-content: center;
//           align-items: center;
//           text-align: center;

//           .avtar {
//             display: flex;
//             padding-top: 18px;
//             padding-left: 4px;
//             font-size: 26px !important;
//             height: 73px;
//             width: 73px;
//             color: #ffffff !important;
//             border-radius: 50%;
//             justify-content: center !important;
//             text-align: center !important;
//           }
//         }
//       }

//       .title-name {
//         font-style: normal;
//         font-weight: 700;
//         font-size: 24px;
//         line-height: 34px;
//         color: #232323;
//         margin-top: 13px !important;
//         bottom: 4.95%;
//       }

//       .title-description-grid {
//         padding-top: 10px;

//         .title-description {
//           font-style: normal;
//           font-weight: 400;
//           font-size: 14px;
//           text-align: center;
//           color: #606060;
//         }
//       }
//     }

//     .profile-name-grid {
//       text-align: center;
//       margin-bottom: 0px;

//       .profilename {
//         color: #0b0b45;
//       }

//       .grid-myprofile {
//         text-align: center;
//         margin-top: 8px;
//         margin-bottom: 8px;

//         .one-myprofile {
//           width: 223px;
//         }
//       }
//     }
//   }

//   @media only screen and (max-width: 484px) {
//     .profile-user-detail-component {
//       min-width: 284px !important;
//     }
//   }

//   .branch-selection-component {
//     text-align: center;
//     padding-top: 10px;
//     padding-bottom: 10px;
//     border-bottom: 1px solid #e0e0e0;

//     .branch-selection-btn {
//       width: 223px;
//       height: 37px;

//       .branch-selection {
//         color: #0b0b45 !important;
//         white-space: nowrap;
//         text-overflow: ellipsis;
//         /* for internet explorer */
//         overflow: hidden;
//         width: 190px;
//         text-align: left;
//         text-transform: none;
//       }
//     }
//   }

//   .organization-component {
//     max-height: 155px;
//     overflow-y: auto;
//     overflow-x: auto;
//     border-bottom: 1px solid #e0e0e0;

//     .organization-list-data {
//       width: 100% !important;

//       .MuiListItem-button {
//         padding: 8px 8px;
//       }

//       .organization-list-icon {
//         margin-right: 10px;
//       }

//       .organization-list {
//         padding-left: 10px;
//         width: 100%;
//         font-weight: 600;
//       }
//     }

//     .add-another-organization-containter {
//       width: 100%;
//       display: inline-flex;
//       align-items: center;
//       padding: 8px 8px;
//       border-radius: 4px;
//     }

//     .add-another-organization-containter-disable {
//       width: 100%;
//       display: inline-flex;
//       align-items: center;
//       padding-top: 8px;
//       padding-right: 11px;
//       padding-bottom: 5px;
//       border-radius: 0px;
//       background-color: #e0e0e0;
//     }

//     .add-another-organization-containter:hover {
//       background-color: rgba(0, 0, 0, 0.04) !important;
//       cursor: pointer;
//     }
//   }

//   //scrollbar
//   .organization-component::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     border-radius: 8px;
//     background-color: #f5f5f5;
//   }

//   .organization-component::-webkit-scrollbar {
//     width: 8px;
//     background-color: #f5f5f5;
//   }

//   .organization-component::-webkit-scrollbar-thumb {
//     border-radius: 8px;
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     background-color: $grey-600;
//   }

//   .financial-list-icon {
//     height: 40px;
//     border-radius: 100%;
//     padding: 4px;
//     margin-right: 25px;
//   }

//   .sign-out-grid {
//     padding-top: 8px;
//     padding-bottom: 0px;
//     text-align: center;

//     .sign-out-btn-myprofile {
//       width: 223px;
//     }
//   }
// }

.skeleton-language {
  max-width: 100%;
  height: 40px !important;
}

.MuiDialogActions-root .button-delete {
  background-color: $danger !important;
  color: $white !important;
}

.MuiDialogActions-root .button-delete {
  background-color: $danger !important;
  color: $white !important;
}

.skeleton-input-language {
  height: 40px !important;
  margin: 10px !important;
}

// FOR TABLE HEADER COLOR - DON't REMOVE
// sx={{
//   "& .MuiTableCell-root": {
//     // borderRadius: "10px", // Apply border radius to header cells
//     backgroundColor: "#f0f0f0", // Optional: header background color
//   },
//   "& .MuiTableRow-root:first-of-type .MuiTableCell-root:first-of-type":
//     {
//       borderTopLeftRadius: "10px", // Top-left corner
//       borderBottomLeftRadius:'10px'
//     },
//   "& .MuiTableRow-root:first-of-type .MuiTableCell-root:last-of-type": {
//     borderTopRightRadius: "10px", // Top-right corner
//      borderBottomRightRadius:'10px'
//   },
// }}
.editor-container .ck-editor__editable_inline {
  min-height: 300px;
  /* Set your desired height here */
}

.phone-input input {
  border-radius: 8px;
  background-color: #fafbfd;
  height: 40px;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ql-container {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ql-editor {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.react-quill-alignment-table-cell {
  .ql-editor {
    height: 100px;
  }
}

.blink {
  animation: blink-animation 2s steps(6, start) infinite;
  -webkit-animation: blink-animation 2s steps(6, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.bold-600 {
  font-weight: 600 !important;
}

.bolder {
  font-weight: bolder !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.font-size-30 {
  font-size: 30px !important;
}

.font-size-40 {
  font-size: 40px !important;
}

.font-size-50 {
  font-size: 50px !important;
}

.width-100-percent {
  width: 100% !important;
}

.text-field-container-width {
  display: contents;
}

.input-end-andorment-border {
  border-left: 1px solid $grey-300 !important;
}

.input-start-andorment-border {
  border-right: 1px solid $grey-300 !important;
}

.select-border {
  border-left: 1px solid $grey-300 !important;
}

.small-scrren-uints {
  display: flex;
  justify-content: space-between;
  border: 1px solid $grey-300 !important;
  border-radius: 8px !important;
  overflow: hidden;
}

.unit-type-medicine-dose {
  padding-left: 2px;
}

.billing-list-data {
  border: 1px solid !important;
  border-color: #ccc !important;
  border-radius: 8px !important;
  padding: 10px 5px 10px 10px !important;
  margin-bottom: 10px !important;
  // width: 380px !important;
}

.selected {
  border: #2e5dd7 2px solid !important;
}

.icon-design-inpopup {
  color: #2e5dd7;
  // height: 1.3em !important;
  // width: 1.3em !important;
  margin-top: 4px;
  margin-right: 10px;
}

.background-grey-400 {
  background-color: $grey-400 !important;
}

.grey-400 {
  color: $grey-600 !important;
}

.tiptap {
  min-height: 100px;
  outline: none;
}

.tiptap-border {
  border: 1px solid $grey-300 !important;
  border-radius: 8px !important;
}

.link-cursor {
  cursor: pointer;
}

.quick-opd-detail-container {
  background-color: $grey-100;
}

.draggable-list-container-scrollable {
  max-height: 350px;
  overflow-y: auto;
}

.previous-next-button {
  border: 1px solid $grey-400;
  border-radius: 8px;
}

.quick-chip {
  border: 1px solid rgb(198 220 235);
  border-radius: 8px;
  padding: 8px 12px;
  color:#222;
  font-size: 12px;
  cursor: pointer;
}

.quick-chip:hover{
  background-color: #e1eaf1;
}