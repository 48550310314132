//color system
//$primary: #2e67a3;
//$primary: #3b75ff; 
//$primary: #3b75ff;
$primary:#285cdb;


$secondary: rgb(65, 77, 85);
//$secondary: #BC3965;
$disbled-shade: #C8CDD0;
$icon-color: rgb(65, 77, 85);
$warning-light: #fff3cd;
$warning-border: #ffeeba;
//$icon-hover-color: #2e67a3;
$icon-hover-color: #3b75ff;

$title: #172026;
$sub-title: #333333; //#9CA5AA;
$danger: #d32f2f;
$border-color: #848f95;
$text-color: #414d55;
$text-bg-color: #FAFBFD;
$text: #343F46;
$border: #E9EBEC;
$background-shade: #F4F5F6;
$bottom-backgroound: #f3f3f3;
$no-data: #4C5C66;

$grey-100: #f1f5f8;
$grey-200: #e9e9ef;
$grey-300: #cbcbda;
$grey-400: #ced4da;
$grey-500: #bdc1cd;
$grey-600: #74788d;
$grey-700: #495057;
$grey-800: #343a40;
$grey-900: #212529;


// HMIS color code

//$background-color: #fafbfd;
$background-color: #f1f5f8;

$white: #fff;
//$sidebar-bg: #fbfaff;
$sidebar-bg: #f1f5f8;
$sidebar-menu-item-color: #545a6d;
// $title-label-color: #333448;
$title-label-color: #414D56;

//font system
$body-font-size: 14px;
$body-font-weight: 400;
$body-line-height: 1.5;
$body-color : $grey-700;

$success-color: #448944;
$info-color: #3780d7;
$warning-color: #c19603;
$warning-light: #ffeeba;
$danger-color: #943432;
$dark-color: #495057;
$hover-color: #f1f5f8;

:export {
    colorPrimary: $primary;
    colorSecondary: $secondary;
    bodyBg: $background-color ;
}