@import "../../theme/Palette/variable.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


.not_found_img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.inner_right_box {
    width: 100%;
    height: 100%;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.page_not_found_msg {
    margin-bottom: 10px !important;
    text-wrap: nowrap;
}

.not_found_msg {
    font-size: 1.5em;
    text-align: center !important;
}

.another_msg {
    margin-bottom: 20px !important;
}

// .primary-button {
//     // background-color: gray !important;
//     // color: white;
// }

.web_link {
    color: $info-color;
}

.width-mdd {
    // width: 100% !important;
    margin: auto !important;
}