.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full page height */
  background-color: #f5f7fa; /* Background color for the loader */
}

.spinner {
  animation: rotate 2s linear infinite;
}

.path {
  stroke: #3f51b5; /* Primary color of the spinner */
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.5s ease-in-out infinite;
}

/* Rotation animation */
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

/* Dash animation */
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -125px;
  }
}
