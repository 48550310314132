@import '../../../theme/theme-fonts.scss';
@import '../../../theme/Palette/variable.scss';


.fontsmall {
  font-size: 10px !important;
}

.logo_grid {
  display: flex;
  cursor: pointer;
  border: 1px solid #e9e9ef;
  border-radius: 5px;
  padding-left: 8px;
  height: "100%";
}

.logo_image {
  border-radius: 5px;
  max-height: 35px;
  max-width: 80px;
  min-width: 68px;
}

.drawer-container {
  height: calc(100% + 1px) !important;
  flex-direction: column;
  padding: 40px;
  //border-right-color: rgb(228, 234, 240);
  // border-right: 1px solid rgba(0, 0, 0, 0.12);
  
  //background-color: $text-bg-color !important;
  background-color: #f1f5f8 !important;
  right: auto;
  left: 0px;
  transform: none;
  transition: transform 400ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: $grey-700;
  font-size: 1rem !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 20px !important;
}

.drawer-main div.MuiDrawer-paperAnchorLeft{
  box-shadow: rgba(20, 46, 110, 0.1) 0px 1px 8px !important;
}
// .main-profie-container .MuiPaper-elevation {
//   border-radius: 8px;
//   top: 72px !important;
//   box-shadow:
//     0px 1px 2px 0px rgb(60 64 67 / 30%),
//     0px 2px 6px 2px rgb(60 64 67 / 15%) !important;

//   .profile-user-detail-component {
//     border-bottom: 1px solid $grey-200;
//     min-width: 295px !important;
//     max-width: 427px !important;
//     background-color: #ffffff;

//     .profile-component-grid {
//       .organization-container {
//         background-color: $warning-light;
//         width: 100%;
//         border: solid 1px $warning-border;
//         display: inline-flex;
//         align-items: center;
//         //  justify-content: center;
//         padding: 8px 8px;
//         border-radius: 4px;
//       }

//       .avatar-upload {
//         min-height: 74px;
//         // position: relative;
//         // max-width: 97px;
//         // margin-right: auto;
//         // margin-left: auto;
//         margin-top: 16px;
//         margin-bottom: 16px;
//         width: 100% !important;

//         .avatar-edit {
//           position: absolute;
//           right: 19px;
//           z-index: 1;
//           bottom: 1px;
//         }

//         .avatar-preview {
//           width: 100%;
//           //width: 141px;
//           // height: 140px;
//           position: relative;
//           // border-radius: 100%;
//           display: inline-flex;
//           justify-content: center;
//           align-items: center;
//           text-align: center;

//           .avtar {
//             display: flex;
//             padding-top: 18px;
//             padding-left: 4px;
//             font-size: 26px !important;
//             height: 73px;
//             width: 73px;
//             color: #ffffff !important;
//             border-radius: 100px;
//             justify-content: center;
//             text-align: center;
//           }
//         }
//       }

//       .title-name {
//         font-style: normal;
//         font-weight: 700;
//         font-size: 24px;
//         line-height: 34px;
//         color: #232323;
//         margin-top: 13px !important;
//         bottom: 4.95%;
//       }

//       .title-description-grid {
//         padding-top: 10px;

//         .title-description {
//           font-style: normal;
//           font-weight: 400;
//           font-size: 14px;
//           text-align: center;
//           color: #606060;
//         }
//       }
//     }

//     .profile-name-grid {
//       text-align: center;
//       margin-bottom: 0px;

//       .profilename {
//         color: #0b0b45;
//       }

//       .grid-myprofile {
//         /* padding-top: 10px; */
//         text-align: center;
//         margin-top: 8px;
//         margin-bottom: 8px;

//         .one-myprofile {
//           width: 223px;
//         }
//       }
//     }
//   }

//   @media only screen and (max-width: 484px) {
//     .profile-user-detail-component {
//       min-width: 284px !important;
//     }
//   }

//   .branch-selection-component {
//     text-align: center;
//     padding-top: 10px;
//     /* padding-right: 16px; */
//     padding-bottom: 10px;
//     /* padding-left: 17px; */
//     border-bottom: 1px solid #e0e0e0;

//     .branch-selection-btn {
//       width: 223px;
//       height: 37px;

//       .branch-selection {
//         color: #0b0b45 !important;
//         white-space: nowrap;
//         text-overflow: ellipsis;
//         /* for internet explorer */
//         overflow: hidden;
//         width: 190px;
//         text-align: left;
//         text-transform: none;
//       }
//     }
//   }

//   .organization-component {
//     max-height: 155px;
//     overflow-y: auto;
//     overflow-x: auto;
//     border-bottom: 1px solid #e0e0e0;

//     .organization-list-data {
//       // padding: 4px 8px;
//       width: 100% !important;

//       .MuiListItem-button {
//         padding: 8px 8px;
//       }

//       .organization-list-icon {
//         margin-right: 10px;
//       }

//       .organization-list {
//         padding-left: 10px;
//         width: 100%;
//         font-weight: 600;
//       }
//     }

//     .add-another-organization-containter {
//       width: 100%;
//       display: inline-flex;
//       align-items: center;
//       //justify-content: center;
//       padding: 8px 8px;
//       border-radius: 4px;
//       // //padding-left: 50px;
//     }

//     .add-another-organization-containter-disable {
//       width: 100%;
//       display: inline-flex;
//       align-items: center;
//       padding-top: 8px;
//       padding-right: 11px;
//       padding-bottom: 5px;
//       border-radius: 0px;
//       background-color: #e0e0e0;
//     }

//     // .add-another-organization-containter-disable:hover {
//     //   cursor: none;
//     // }

//     .add-another-organization-containter:hover {
//       background-color: rgba(0, 0, 0, 0.04) !important;
//       cursor: pointer;
//     }
//   }

//   //scrollbar
//   .organization-component::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     border-radius: 8px;
//     background-color: #f5f5f5;
//   }

//   .organization-component::-webkit-scrollbar {
//     width: 8px;
//     background-color: #f5f5f5;
//   }

//   .organization-component::-webkit-scrollbar-thumb {
//     border-radius: 8px;
//     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//     background-color: $grey-600;
//   }

//   .financial-list-icon {
//     height: 40px;
//     border-radius: 100%;
//     padding: 4px;
//     margin-right: 25px;
//   }

//   .sign-out-grid {
//     padding-top: 8px;
//     padding-bottom: 0px;
//     text-align: center;

//     .sign-out-btn-myprofile {
//       width: 223px;
//     }
//   }
// }

.active-master-class-icon {
  color: $primary;
  font-size: 22px;
}

.activeStatus {
  color: $primary !important;
}

.MuiPaper-root .drawer-container {
  box-shadow: rgba(20, 46, 110, 0.1) 0px 1px 8px !important;
  padding-top: 8px !important;
}

.MuiMenuItem-root {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: $font-family-inter !important;
}

.icon-color:hover {
  color: $primary !important;
}

// .nav-ListItemButton {
//   .menu-icon-span {
//     border-radius: 8px;
//     height: 24;
//     width: 24;
//     display: flex;
//     justify-content: center;
//     text-align: center;
//     align-items: center;
//     padding-left: 8px;

//     .menu-icon {
//       font-size: 24px;
//     }
//   }

//   .menu-item-text {
//     font-size: 15px !important;
//   }

//   menu-item-icon-more {
//     font-size: 22px !important;
//   }

//   padding: 0px 0px;
// }

// .nav-ListItemButton :hover {
//   .menu-icon-span {
//     color: $primary;

//     .menu-icon {
//       color: $primary;
//     }
//   }

//   .menu-item-text {
//     color: $primary;
//     font-size: 15px !important;
//   }

//   .menu-item-icon-more {
//     color: $primary !important;
//     font-size: 22px !important;
//   }
// }

// .active-master-class :hover {
//   color: $secondary !important;

//   .menu-icon {
//     color: $white !important;
//     background-color: $secondary !important;
//   }

//   .menu-item-text {
//     color: $white !important;
//     font-size: 15px !important;
//   }
// }

// .active-menu-icon {
//   border: '1px solid red';
//   padding: 0px;
//   padding-top: 5px;
//   color: $primary !important;
//   border-radius: 12px;

//   .menu-icon-span {
//     border: '1px solid red';
//     border-radius: 10px;
//     height: 24;
//     width: 50px;
//     display: flex;
//     justify-content: center;
//     text-align: center;
//     align-items: center;

//     .menu-icon {
//       background-color: #e9eefa !important;
//       border: '1px solid red';
//       font-size: 24px;
//     }
//   }
// }

// .active-master-class {
//   background-color: #e9eefa !important;
//   color: $primary !important;
//   border-radius: 12px;

//   .menu-icon-span {
//     border-radius: 10px;
//     height: 24px;
//     width: 24;
//     display: flex;
//     justify-content: center;
//     text-align: center;
//     align-items: center;
//     color: $white !important;

//     .menu-icon {
//       color: $primary;
//     }
//   }

//   .menu-item-text {
//     color: $primary;
//     font-size: 15px !important;
//   }

//   menu-item-icon-more {
//     color: $white;
//     font-size: 15px !important;
//   }
// }


.nav-ListItemButton {
  .menu-icon-span {
      background-color: $grey-200;
      border-radius: 10px;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;

      .menu-icon {
          font-size: 24px;
          color: $secondary;
      }
  }
  //margin-right: 12px;
  margin-right: 12px !important;

}

.nav-ListItemButton :hover {
  .menu-icon-span {
      background-color: $secondary;

      .menu-icon {
          color: $white;
      }
  }
  
}

.active-master-class {
  .menu-icon-span {
      background-color: $secondary;
      border-radius: 10px;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;

      .menu-icon {
          color: $white;
      }
  }
}

.drawer-container span {
  font-size: 14px !important;
  font-weight: 500;
}

.MuiSvgIcon-root {
  font-size: 22px !important;
}

.main-profie-container {
  border-radius: 16px;
  padding: 8px 2px;
  top: 72px !important;
  border: solid 1px $grey-200;
  max-width: "329px !important";
   box-shadow: 0px 1px 2px 0px rgb(60 64 67 / 30%),
      0px 2px 6px 2px rgb(60 64 67 / 15%) !important;

  .profile-user-detail-component {
    border-bottom: 1px solid $grey-200;
    min-width: 295px !important;
    max-width: 427px !important;

    .profile-component-grid {
      .organization-container {
        background-color: $grey-200;
        width: 100%;
        border: solid 1px $grey-200;
        display: inline-flex;
        align-items: center;
        padding: 8px 8px;
        border-radius: 4px;
      }

      .avatar-upload {
        min-height: 74px;
        margin-top: 16px;
        margin-bottom: 16px;
        width: 100% !important;

        .avatar-edit {
          position: absolute;
          right: 19px;
          z-index: 1;
          bottom: 1px;
        }



        .avatar-preview {
          width: 100%;
          position: relative;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          text-align: center;

          .avtar {
            display: flex;
            padding-top: 18px;
            padding-left: 4px;
            font-size: 26px !important;
            height: 73px;
            width: 73px;
            color: #ffffff !important;
            border-radius: 100px;
            justify-content: center;
            text-align: center;
          }
        }
      }


      .title-name {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        color: #232323;
        margin-top: 13px !important;
        bottom: 4.95%;
      }

      .title-description-grid {
        padding-top: 10px;

        .title-description {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          text-align: center;
          color: #606060;
        }
      }
    }

    .profile-name-grid {
      text-align: center;
      margin-bottom: 0px;

      .profilename {
        color: $primary
      }

      .text-size-medium {
        color: $primary !important;
      }

      .grid-myprofile {
        color: $title;
        text-align: center;
        margin-top: 8px;
        margin-bottom: 8px;

        .one-myprofile {
          width: 223px;
          border-color: $border-color;
          color: $title;
        }
      }
    }
  }

  @media only screen and (max-width: 484px) {
    .profile-user-detail-component {
      min-width: 284px !important;
    }
  }

  .branch-selection-component {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;

    .branch-selection-btn {
      width: 223px;
      height: 37px;
      border-color: $border-color !important;

      .branch-selection {
        color: $title !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        /* for internet explorer */
        overflow: hidden;
        width: 190px;
        text-align: left;
        text-transform: none;
      }
    }
  }

  //scrollbar
  .organization-component::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    background-color: #f5f5f5;
  }

  .organization-component::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  .organization-component::-webkit-scrollbar-thumb {
    border-radius: 8px;
    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .financial-list-icon {
    height: 40px;
    border-radius: 100%;
    padding: 4px;
  }

  .sign-out-grid {
    padding-top: 8px;
    padding-bottom: 0px;
    text-align: center;

    .sign-out-btn-myprofile {
      width: 223px;
    }
  }
}
.sign-out-grid-at-login {
  display: flex;
  padding-top: 8px;
  padding-bottom: 0px;
  justify-content: space-evenly;
  text-align: center;
}
.profile-user-detail-component-at-login {
  border-bottom: 1px solid $grey-200;
  min-width: 327px !important;
  max-width: 427px !important;

  .profile-component-grid-at-login {
    .organization-container {
      width: 100%;
      display: inline-flex;
      align-items: center;
      padding: 8px 8px;
      border-radius: 4px;

      .profilename {
        font-size: 20px !important;
        color: #0b0b45 !important;
        font-weight: 600;
        max-width: 260px;
      }
    }

    .avatar-container {
      //  margin-top: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      margin-left: 10px;
      display: flex;

      // .avatar-preview-at-login {
      .avtar-at-login {
        display: flex;
        padding-top: 0px;
        padding-left: 1px;
        font-size: 23px !important;
        height: 37px;
        width: 39px;
        color: #161515 !important;
        border-radius: 100px;
        /* justify-content: center; */
        text-align: center;
      }

      .text-size-medium {
        font-size: inherit;
        padding-top: 9px;
        padding-left: 10px;
      }
    }
  }
}

@media only screen and (width: 768px) {
  .new {
    min-height: 56px !important;
  }
}

@media only screen and (width: 1024px) {
  .new {
    min-height: 56px !important;
  }
}